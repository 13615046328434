import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const FlexPage = () => (
	<Layout>
		<SEO title="Raster"/>
		<div className="bg-white force">
			<div className="edge">
				<h1>Rasters</h1>
			</div>

			<div className="vh-25 raster block-padding-vertical block-margin-vertical">
				<div className="edge trim">
					<h3>Raster Default</h3>
				</div>
			</div>

			<div className="vh-25 raster raster-center block-padding-vertical block-margin-vertical">
				<div className="edge trim">
					<h3>Raster Default + Center</h3>
				</div>
			</div>

			<div className="vh-25 raster raster-inverted block-padding-vertical block-margin-vertical text-white">
				<div className="edge trim">
					<h3>Raster Inverted</h3>
				</div>
			</div>

			<div className="raster">
				<div className="vh-25 raster raster-10 block-padding-vertical block-margin-vertical">
					<div className="edge trim">
						<h3>Raster and Raster 10</h3>
					</div>
				</div>
			</div>

			<div className="vh-25 raster raster-vertical block-padding-vertical block-margin-vertical">
				<div className="edge trim">
					<h3>Raster Vertical</h3>
				</div>
			</div>

			<div className="vh-25 raster raster-horizontal block-padding-vertical block-margin-vertical">
				<div className="edge trim">
					<h3>Raster horizontal</h3>
				</div>
			</div>
		</div>
	</Layout>
)

export default FlexPage
